import {
  Composer,
  Container,
  MessageList,
  WebchatProvider,
  getClient,
} from "@botpress/webchat";
import { buildTheme } from "@botpress/webchat-generator";
import React from "react";
import { useEffect, useRef } from "react";

const { theme, style } = buildTheme({
  themeName: "galaxy",
  themeColor: "#0000000",
});

//Add your Client ID here ⬇️
const clientId = "09915ad3-5c06-4001-8920-4b406ca5aee9";

const config = {
  composerPlaceholder: "What would you like to know?",
  botName: "Fullpotential AI",
  botAvatar:
    "https://files.bpcontent.cloud/2024/11/23/07/20241123075815-7MTCA1RE.png",
  email: {
    title: "fullpotentialai@gmail.com",
    link: "mailto:fullpotentialai@gmail.com",
  },
  website: {
    title: "https://fullpotential.com",
    link: "https://fullpotential.com",
  },

  showPoweredBy: false,
};

export default function App() {
  const client = getClient({ clientId });

  const messageListRef = useRef();

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [client.messages]);

  // return (
  //   <div style={{ width: "100vw", height: "100vh" }}>
  //     <style>{style}</style>
  //     <WebchatProvider theme={theme} client={client} configuration={config}>
  //       <div>
  //         {/* <Fab onClick={toggleWebchat} />
  //       <div
  //         style={{
  //           display: isWebchatOpen ? "block" : "none",
  //         }}
  //       > */}
  //         {/* <Webchat /> */}
  //         {/* </div> */}
  //       </div>
  //       <Container>
  //         <Header />
  //         <MessageList />
  //         <Composer>
  //           <div style={{ width: "100%" }} >qwe</div>
  //           {/* <ComposerInput />
  //           <ComposerButton /> */}
  //         </Composer>
  //       </Container>
  //     </WebchatProvider>
  //   </div>
  // );

  return (
    <div className="flex min-h-screen">
      <style>{style}</style>
      <div className="w-full" style={{ overflowY: 'auto' }}>
        <WebchatProvider theme={theme} client={client} configuration={config}>
          <Container>
            {/* <div ref={messageListRef} style={{ overflowY: 'auto', height: "100vh" }}> */}
              <MessageList />
            {/* </div> */}
            <Composer />
          </Container>
        </WebchatProvider>
      </div>
    </div>
  );
}
